import {
  LocalizableString,
  useBoolean,
} from "@virtual-repetitions/lib-components";
import React, { ReactNode, useEffect, useState } from "react";
import { ControlledConfirmDialog } from "./ControlledConfirmDialog";

export interface DOMDrivenConfirmDialogProps {
  body?: ReactNode;
  cancelText?: LocalizableString;
  confirmText?: LocalizableString;
  dangerousHTMLBody?: string;
  onConfirmId: string;
  title: string;
  triggerId: string;
}

export const DOMDrivenConfirmDialog = ({
  body,
  cancelText,
  confirmText,
  dangerousHTMLBody,
  onConfirmId,
  title,
  triggerId,
}: DOMDrivenConfirmDialogProps) => {
  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);
  const [triggerDOM, setTriggerDOM] = useState<HTMLElement | null>(null);
  const [confirmDOM, setConfirmDOM] = useState<HTMLElement | null>(null);

  // set up "blocker" to prevent action from happening
  useEffect(() => {
    const triggerDOMEl = document.getElementById(triggerId);
    const confirmDOMEl = document.getElementById(onConfirmId);

    // explicit error check
    if (triggerDOMEl == null || confirmDOMEl == null) {
      console.error("DOMDrivenConfirmDialog: invalid triggerId or confirmId");
      return;
    }

    setTriggerDOM(triggerDOMEl);
    setConfirmDOM(confirmDOMEl);

    const eventListener = (e) => {
      e.preventDefault();
      open();
    };
    triggerDOMEl.addEventListener("click", eventListener);
    return () => {
      triggerDOMEl.removeEventListener("click", eventListener);
    };
  }, []);

  let bodyContent: ReactNode = " ";
  if (body) {
    bodyContent = body;
  } else if (dangerousHTMLBody) {
    bodyContent = (
      <div dangerouslySetInnerHTML={{ __html: dangerousHTMLBody }} />
    );
  }

  return (
    <ControlledConfirmDialog
      showDialog={isOpen}
      onConfirm={() => confirmDOM && confirmDOM.click()}
      onDismiss={close}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/no-children-prop
      children={bodyContent}
      {...{
        title,
        confirmText,
        cancelText,
      }}
    />
  );
};

export default DOMDrivenConfirmDialog;
