// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".
// import "bootstrap/js/dist/dropdown";
import { Socket } from "phoenix";
import "phoenix_html";
import { LiveSocket } from "phoenix_live_view";
import "popper.js";
import "react-phoenix";
import "vreps-web-shared/alert";
import { DOMDrivenConfirmDialog } from "vreps-web-shared/components/Dialog/DOMDrivenConfirmDialog";
import { mountForTemplate } from "vreps-web-shared/utils/mountForTemplate";
// NOTE: Missing assets/js/_hooks/ cause vite build error here. (Re)generate _hooks with `mix compile`
import Hooks from "../../../../../assets/js/_hooks";
import * as telInput from "./scripts/telInput";
import "./themes/base";
import "@virtual-repetitions/draw/styles.css";

let csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, {
  hooks: { ...Hooks },
  params: { _csrf_token: csrfToken },
});

// Connect if there are any LiveViews on the page
liveSocket.connect();

// Expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
// The latency simulator is enabled for the duration of the browser session.
// Call disableLatencySim() to disable:
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket;

document.addEventListener("DOMContentLoaded", function () {
  telInput.onDOMReady();
});

mountForTemplate({
  DOMDrivenConfirmDialog,
});
