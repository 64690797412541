"use strict";

import intlTelInput from "intl-tel-input/build/js/intlTelInput";

// here, the index maps to the error code returned from getValidationError - see readme
// will need to localize eventually
const errorMessagesMap = [
  "Invalid number",
  "Invalid country code",
  "Too short",
  "Too long",
  "Invalid number",
];

// add coloring to telInput element to show validation
const validateTelInput = (telInput, intlTelInstance, errorMsg) => {
  errorMsg.innerHTML = "";

  if (intlTelInstance.isValidNumber()) {
    telInput.classList.add("text-success");
    telInput.classList.remove("text-error");
  } else {
    // invalid number. Show error
    const errorCode = intlTelInstance.getValidationError();
    errorMsg.innerHTML = errorMessagesMap[errorCode];
    telInput.classList.add("text-error");
    telInput.classList.remove("text-success");
  }
};

export const onDOMReady = () => {
  const telInputs = document.querySelectorAll('input[type="tel"]');

  for (let telInput of telInputs) {
    const intlTelInstance = intlTelInput(telInput, {
      customPlaceholder: function (
        selectedCountryPlaceholder,
        selectedCountryData
      ) {
        return "e.g. " + selectedCountryPlaceholder;
      },
      formatOnDisplay: true,
      separateDialCode: true,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.2/js/utils.js",
    });

    // add error message to each tel input
    var errorMessage = document.createElement("div"); // Create with DOM
    errorMessage.classList.add("text-error");
    errorMessage.innerHTML = "";

    telInput.parentNode.after(document.createElement("br"), errorMessage);

    // adding validation hints
    telInput.addEventListener("blur", () => {
      validateTelInput(telInput, intlTelInstance, errorMessage);
    });
    telInput.addEventListener("change", () => {
      validateTelInput(telInput, intlTelInstance, errorMessage);
    });
    telInput.addEventListener("keyup", () => {
      validateTelInput(telInput, intlTelInstance, errorMessage);
    });

    telInput.form.addEventListener("submit", function (e) {
      if (telInput.value.length > 0 && !intlTelInstance.isValidNumber()) {
        e.preventDefault();
        return;
      }
      telInput.value = intlTelInstance.getNumber();
    });
  }
};
